<template>
  <v-app>
    <div class="container">
      <v-file-input
        v-model="image_vaults"
        small-chips
        :show-size="1000"
        counter
        outlined
        placeholder="Select your files"
        multiple
        clearable
        label="Add files"
        @change="handleFileUpload"
      >
        <template v-slot:selection="{ text, index, file }">
          <v-chip small text-color="white" color="#295671" close @click:close="remove(index)">
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>
      <v-row>
        <v-col sm="3" md="3" v-for="(file, index) in image_vaults" :key="index">
          <!-- <img :ref="'file'" class="img-fluid" :src="file.path" width="130" height="130" />
          {{ file.name }} -->

          <img :ref="'file'" class="img-fluid" :src="file.previewUrl" width="130" height="130" />
          {{ file.name }}
        </v-col>
      </v-row>
      <v-btn color="primary" rounded @click.prevent="submitUpload">
        <v-icon right dark>mdi-cloud-upload</v-icon>
        <span>Upload</span>
      </v-btn>
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Upload from '@/components/workflow/inspections/upload.vue'
import mitt from 'mitt'

export default {
  created: function() {},
  data() {
    return {
      currFiles: [],
      readers: [],
      previousFiles: [],
      image_vaults: [],
    }
  },

  methods: {
    remove(index) {
      this.image_vaults.splice(index, 1)
    },
    fileAdded() {
      console.log(this.image_vaults)
      if (this.previousFiles.length > 0) {
        this.image_vaults.push(...this.previousFiles)
      }
    },

    handleFileUpload(event) {
      const files = event.target.files
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader()
        reader.onload = e => {
          this.image_vaults.push({
            file: files[i],
            previewUrl: e.target.result,
          })
        }
        reader.readAsDataURL(files[i])
      }
    },
    previewImage: function() {
      this.image_vaults.forEach((file, f) => {
        this.readers[f] = new FileReader()
        this.readers[f].onload = e => {
          let fileData = this.readers[f].result
          let imgRef = this.$refs.file[f]
          imgRef.src = fileData
          //console.log(fileData)
          // send to server here...
        }
        this.fileAdded()
        this.readers[f].readAsDataURL(this.image_vaults[f])
      })
    },
    submitUpload: function() {
      let currentObj = this
      const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      let formData = new FormData()

      //append files
      for (let i = 0; i < this.image_vaults.length; i++) {
        formData.append('image_vaults[]', this.image_vaults[i])
        //formData.append('inspection_item_id', this.inspection_item_id)
      }

      //send upload request
      this.$store
        .dispatch(`inspection/storeNewImageVault`, formData, config)
        .then(function(response) {
          this.close()
          currentObj.success = response.data.success
          currentObj.filename = ''
        })
        .catch(function(error) {
          currentObj.output = error
        })
    },
  },

  computed: {
    ...mapGetters({}),
  },
  watch: {
    image_vaults(val) {
      this.previousFiles = val
    },
  },
}
</script>

<style></style>
